
@mixin respond($breakpoint) {
    @if $breakpoint == x-large {
      @media (max-width: 1160px) { @content };
    }
    @if $breakpoint == large {
      @media (max-width: 940px) { @content };
    }
    @if $breakpoint == medium {
        @media (max-width: 425px) { @content };
      }
    @if $breakpoint == small {
        @media (max-width: 320px) { @content };
      }
  }

.join{
   
    .join-heading{
        color: #C63A3A;
        padding-bottom:15px;
        font-family: heavitas !important;
        font-size: 30px !important;
    }

    .join-image{
        
       // padding: 220px ;
       background-image: url("../../assets/img/join3.jpg");

    //    background-size: auto;
    //    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
       }
  
    .join-section{
        // background: yellow;
        // display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  width: 100%;
//   max-width: 100vw;
  padding:70px 120px;

        @include respond(x-large) {
            // font-size: 52px;
        }
        
        @include respond(large) {
            // font-size: 32px;
         padding:70px 50px;
         text-align: center;
         
        }

        @include respond(medium) {
       // padding-top: 50px;
         padding:70px 50px;
         text-align: center;

        }

        @include respond(small) {
        //  font-size: 15px;
        }

    }  
    
    .btn{
        
        text-transform: uppercase;
        font-weight: 700;
        // position: relative;
        // overflow: hidden;
        // display: inline-block;
        // backface-visibility: hidden;
        // float: right;
        border: none;
        color: white;
        border-radius: 25px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px; 
    
        padding: 10px 40px;
        font-size: 12px; 
        
    }

    .join-p{
        padding-bottom:15px; 
        font-family: montserrat !important;
        font-size: 14px !important;
    }

    .join-btn{
        margin-top: 20px;
        margin-bottom: 20px; 
    }

    
    
}

.modal-driver{
    .modal-content{
        background:  #e4e4e4;
    }
}


.modal-content{
    background: #efeae4;
    padding:18px;
    border:8px solid #000000;
    box-shadow: 0px 0px 30px 0px rgba( 2, 2, 2, 1.00 );

    .modal-body{
        .modal-container { 
            .wpcf7-form{
                .contact-form-wrapper{
                    .title-row{
                        font-family: heavitas !important;
                        width: 100%;
                        text-align: center;
                            .first{
                             color:#C63A3A !important;
                             }
                        .second { 
                            color:#0C527E !important;
                        }
                    }
                    .subtitle-row{
                        font-family: montserrat !important;
                        width: 100%;
                        text-align: center;
                        font-size: 12px !important; 
                        padding-bottom:15px; 
                    }
                    .custom-row{
                        padding: 10px; 
                    }

                    .join-space{

                        @include respond(large) {
                            // font-size: 32px;
                            padding-bottom:20px;
                         
                        }
                
                        @include respond(small) {
                            padding-bottom:15px;
                        }

                    }

                    .custom-exp{
                        padding: 10px; 
                        font-family: montserrat !important;
                        font-size: 12px !important; 
                    }

                    .item-label{
                        font-family: montserrat !important; 
                        font-size: 10px !important; 
                    }
                }
                .contact-form-wrapper input{
                    width: 100%;
                    font-family: montserrat !important;
                    font-size: 12px !important; 
                }

                .equipment-wrapper {
                    padding-top: 20px;
                    font-family: montserrat !important;
                    font-size: 11px !important; 
                }
                
                .wpcf7-list-item label{
                    text-align: center;
                    padding: 5px 15px;
                    font-size: 11px !important; 
                } 

                .wpcf7-list-item input{
                    vertical-align: middle;
                }

                .wpcf7-form-control{
                   border-radius: 20px !important; 
                }

                .wpcf7-text{
                    border: 1px solid #ddd;
                    padding: 8px 15px;
                }
            }
         }
     }
     

        .btn-footer{
            justify-content: center; 
            padding-top: 15px;
            padding-bottom: 15px;
        }

      .btn-driver{
        color: white;
        background-color:#C63A3A;
        text-transform: uppercase;
        font-weight: 700;
        border: none;
        border-radius: 25px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px; 
        font-size: 11px; 
        padding: 10px 15px;
        width: 125px;
        // @include respond(x-large) {
        //     width: 125px;
        //     font-size: 11px; 
        //     padding: 10px 15px;
         
        // }

        // @include respond(medium) {
        //     width: 125px;
        //     font-size: 11px; 
        //     padding: 10px 15px;

        // }

        // @include respond(small) {
        //     width: 125px;
        //     font-size: 11px; 
        //     padding: 10px 15px;
        // }
        }

      .btn-owner{
        color: white;
        background-color:#0C527E;
        text-transform: uppercase;
        font-weight: 700;
        border: none;
        border-radius: 25px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;     
        font-size: 11px; 
        padding: 10px 15px;
        width: 125px;

        // @include respond(large) {
        //     width: 125px;
        //     font-size: 11px; 
        //     padding: 10px 15px;
         
        // }

        // @include respond(medium) {
        //     width: 125px;
        //     font-size: 11px; 
        //     padding: 10px 15px;

        // }

        // @include respond(small) {
        //     width: 125px;
        //     font-size: 11px; 
        //     padding: 10px 15px;
        // }

        }
     
   }

   
   