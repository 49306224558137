@mixin respond($breakpoint) {
    @if $breakpoint == x-large {
      @media (max-width: 1160px) { @content };
    }
    @if $breakpoint == large {
      @media (max-width: 940px) { @content };
    }
    @if $breakpoint == medium {
        @media (max-width: 425px) { @content };
      }
    @if $breakpoint == small {
        @media (max-width: 320px) { @content };
      }
  }

.bg-div{
    background:linear-gradient(0deg, rgba(11, 11, 13, 0.2), rgba(11, 11, 13, 0.3)),  url("../../assets/img/home-bg.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    .head{
        // background: #5a5959; 
        display: flex;
        justify-content: center;
        align-items: center;
        // flex-direction: column;
        // font-size: 75px;
        font-family: heavitas;
        color:white;
       // line-height: 0.1vh;
        min-height: 100vh;
        
        &-div{
            &-second-text{
                font-size: 72px!important;
               @include respond(x-large) {
                font-size: 52px !important;
              }
              
              @include respond(large) {
                font-size: 32px !important;
              }

              @include respond(medium) {
                font-size: 20px !important;
              }

              @include respond(small) {
                font-size: 15px !important;
              }
               padding: 0 !important;
                
            }
            
        }
    }
.call{
   // background: #5a5959; 
    // align-items: center !important;
     justify-content: center;
    display: flex;
    padding-top: 20px;
}
    .btn{
        text-transform: uppercase;
        font-weight: 700;
        position: relative;
        overflow: hidden;
        display: inline-block;
        backface-visibility: hidden;
        // float: right;
        border: none;
        color: white;
        border-radius: 25px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px; 
        background: #C63A3A;
        font-family: montserrat !important;
        padding: 12px 20px;
        font-size: 12px; 
        width: 185px;


        @include respond(x-large) {
            width: 165px;
            font-size: 12px; 
            padding: 10px 15px;
           // font-size: 52px;
          }
          
          @include respond(large) {
            width: 125px;
            font-size: 11px; 
            padding: 10px 15px;
          }

        //   @include respond(medium) {
        //     width: 125px;
        //     font-size: 11px; 
        //     padding: 10px 15px;
        //   }

          @include respond(small) {
            width: 110px;
            font-size: 10px; 
            padding: 10px 15px;
           // font-weight: 200;
            //font-size: 17px;
          }


    }

    .scroll-btn {
        display: block;
        position: absolute;
        bottom:0;
        left: 0;
        right: 0;
        text-align: center;
    }
    .scroll-btn > *:hover,
    .scroll-btn > *:focus,
    .scroll-btn > *.active {
        color: #ffffff;
    }
    .scroll-btn > *:hover,
    .scroll-btn > *:focus,
    .scroll-btn > *:active,
    .scroll-btn > *.active {
        opacity: 0.8;
        filter: alpha(opacity=80);
    }
    .scroll-btn .mouse {
        position: relative;
        display: block;
        width: 35px;
        height: 55px;
        margin: 0 auto 20px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border: 3px solid white;
        border-radius: 23px;
    }
    .scroll-btn .mouse > * {
        position: absolute;
        display: block;
        top: 29%;
        left: 50%;
        width: 8px;
        height: 8px;
        margin: -4px 0 0 -4px;
        background: white;
        border-radius: 50%;
        -webkit-animation: ani-mouse 2.5s linear infinite;
        -moz-animation: ani-mouse 2.5s linear infinite;
        animation: ani-mouse 2.5s linear infinite;
    }
    .social-media{
        width: 40px;
        position: fixed;
        bottom: 0px;
        left: 40px;
        right: auto;
        z-index: 10;
        color: #ffffff;
        &-list{
            display: flex;
            flex-direction: column;
            -webkit-box-align: center;
            align-items: center;
            padding: 0px;
            margin: 0px;
            list-style: none;
            &::after{
                content: "";
                display: block;
                width: 1px;
                height: 90px;
                margin: 0px auto;
                background-color: #fff;
            }
            a{
                display: inline-block;
                text-decoration: none;
                text-decoration-skip-ink: auto;
                color: inherit;
                position: relative;
                transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
                cursor: pointer;
                &:hover{
                    color: #29a19c;
                }
            }
            &-link{
                padding:10px;
                .media-icons{
                    width:24px;
                    height:24px;
                    fill: currentColor;
                }
                &:hover{
                    transform: translateY(-3px);
                }
            }
        }
    }
}

//mouse animation

@-webkit-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@-moz-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}

@keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
// @font-face {
//     font-family: "heavitas";
//     src: local("heavitas"),
//      url("../../assets/font/heavitas.ttf") format("truetype");
//     font-weight: bold;
//     }