@mixin respond($breakpoint) {
    @if $breakpoint == x-large {
      @media (max-width: 1160px) { @content };
    }
    @if $breakpoint == large {
      @media (max-width: 940px) { @content };
    }
    @if $breakpoint == medium {
        @media (max-width: 425px) { @content };
      }
    @if $breakpoint == small {
        @media (max-width: 320px) { @content };
      }
  }

.services{
   // background-color: #e4e4e4;


    .services-heading{
    //   background-color: black;
    //margin-bottom: 0;
    font-size: 30px !important;
    text-align: center;
    text-transform: none;
    position: relative;
   // margin-bottom: 40px;
 //   padding-top: 100px;
    font-family: heavitas !important;
    }

    .services-subheading{
        //   background-color: black;
        //margin-bottom: 0;
        text-align: center;
        text-transform: none;
        position: relative;
        margin-bottom: 28px;
     //   padding-top: 100px;
        font-family: montserrat !important;
        font-size: 14px !important;
        }

    .service-text{
        // padding: 0;
       // background-color: black;
       // overflow: hidden; 
        text-align: center;
        margin-bottom: 40px ;
        font-family: montserrat !important;
        font-size: 14px !important;
        // width: 100%;
//         display: flex;
//   justify-content: center;
//   align-items: center;
    }

    .truck-heading{
        font-family: heavitas !important;
     //   font-size: 8px !important;
        margin-bottom: 15px ;
        margin-top: 15px ;
     //   text-align: center;
    }

    .truck-image{
        padding-left: 15px;
        // display: flex;
        // justify-content: center;
        // align-items: center; 
    }

    .truck-image-less{
        padding-left: 40px;
        // display: flex;
        // justify-content: center;
        // align-items: center; 
        @include respond(large) {
            padding-left: 15px;
    }
}

    .truck-text{
        font-family: montserrat !important;
        font-size: 14px !important;
        

        // @include respond(x-large) {
        //     // font-size: 52px;
        //    }
           
           @include respond(large) {
            padding-bottom: 30px;
           }
 
        //    @include respond(medium) {
        //    padding-bottom: 30px;
        
        //    }
 
        //    @include respond(small) {
        //    //  font-size: 15px;
        //    }
    }
    .left{
        margin-bottom: 20px ;  

       
        
    }

}