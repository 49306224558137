@mixin respond($breakpoint) {
    @if $breakpoint == x-large {
      @media (max-width: 1160px) { @content };
    }
    @if $breakpoint == large {
      @media (max-width: 940px) { @content };
    }
    @if $breakpoint == medium {
        @media (max-width: 425px) { @content };
      }
    @if $breakpoint == small {
        @media (max-width: 320px) { @content };
      }
  }

.header-nav{
    background: transparent !important;
    transition: all 0.5s ease;
 
    .navbar-toggler{
        border-color: #ffff;
        transition: all 0.5s ease;
        &-icon{
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")
        }
    }
    .nav-link{
        display: flex;
        justify-content: center;
        align-items: center;
        color:white !important;
        font-size: 13px;
        font-family: montserrat !important;
        font-weight: bold;
        transition: all 0.5s ease;
        &:hover,&:focus{
            color: #C63A3A !important;
            transition: color 0.3s ease-in-out;
        }
        .button{
            color:#C63A3A;
            border-color: #C63A3A;
            &:hover,&:focus,&:active{
                outline:none !important;
                box-shadow: none !important;
                background-color: transparent !important;
                color:#C63A3A !important;
                border-color: #C63A3A !important;
                transition: all 0.3 ease-in-out;
            }
        }
    }

    .navbar-brand{
        //background: yellow;
        // background-image: url("../../assets/img/gigiW.svg") !important;  
        background-image: url("../../assets/img/gigiW.svg") !important;  
        width: 150px;
        height: 51px;
        background-repeat: no-repeat;
        background-position: center;
       

        @include respond(large) {
            background-image: url("../../assets/img/gigiB.svg") !important;  
            
          }
      }
}


.header-nav.sticky{
   background-color: white !important;
//    opacity: 0.5;
    transition: all 0.5s ease;
    padding-top: 2px;
    padding-bottom: 2px;
    .brand-name,.nav-link{
        color: black !important;
        transition: all 0.5s ease;
        &:hover{
            color: #C63A3A !important;
            transition: color 0.3s ease;
        }
    }
    .navbar-brand{
        //background: yellow;
        background-image: url("../../assets/img/gigiB.svg") !important;  
        background-repeat: no-repeat;
        width: 100px;
        height: 51px;
      }

    .nav-link{
        &:hover{
            background-color: rgba(0,0,0,0.1);
            transition: all 0.5s ease;
        }
        &:nth-last-child(1):hover{
            background-color: white !important;
            transition: all 0.5s ease;
        }
    }
}

