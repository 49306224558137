
@mixin respond($breakpoint) {
    @if $breakpoint == x-large {
      @media (max-width: 1160px) { @content };
    }
    @if $breakpoint == large {
      @media (max-width: 940px) { @content };
    }
    @if $breakpoint == medium {
        @media (max-width: 425px) { @content };
      }
    @if $breakpoint == small {
        @media (max-width: 320px) { @content };
      }
  }


.contact-div{
    background-color: #e4e4e4;
    padding-top: 70px;
    padding-bottom: 40px;

    // .single-row{
    //    background: yellow;
    //     width: 100%;
    //     clear: both;
    //     display:flex;
    //     flex-wrap:wrap;
        
    // }
    .chat-space{
        padding-bottom: 20px;
        
    }
    .chat-heading{
        font-family: heavitas !important;
        font-size: 35px !important;
    }

    .chat-text{
        font-family: montserrat !important;
        font-size: 15px !important;
    }

   .single-col{
    // width:50%;
    // float:left;
    padding-bottom:25px;
    // background:green;
   }

   .single-col input{
   width: 100%;
   }

   .wpcf7-form-control-wrap{
    position:relative;
   }

   .contact-form-wrapper input{
    width: 100%;
    font-family: montserrat !important;
    font-size: 12px !important; 
}

   .wpcf7-form-control{
    border-radius:20px !important;
    border:1px solid #ddd;
    background:white;
    padding:8px 15px;
    // -webkit-box-sizing:border-box;
   }

   .wpcf7-textarea{
    width:100%;
    padding:8px 15px;
    font-family: montserrat !important;
    font-size: 12px !important; 
    
    // background:green;
   }

   .wpcf7-submit{
    text-transform: uppercase;
        font-weight: 700;
      //  position: relative;
      //  overflow: hidden;
      //  display: inline-block;
      //  backface-visibility: hidden;
        // float: right;
        border: none;
        color: white;
        border-radius: 25px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px; 
    
        padding: 10px 30px;
        font-size: 12px; 
        background: #C63A3A;

        @include respond(large) {
            width: 125px;
            font-size: 11px; 
            padding: 10px 15px;
          }

        @include respond(medium) {
            width: 125px;
            font-size: 11px; 
            padding: 10px 15px;
          }
       // background:green;
   }

   .submit-column{
    // background:white;
    // text-align: center;
    display: flex;
  justify-content: center;
  align-items: center;
//   flex-direction: column;
    // align-items: center;


    @include respond(x-large) {
        // font-size: 52px;
       }
       
       @include respond(large) {
        padding-top: 15px;
       }

       @include respond(medium) {
       padding-top: 15px;
    
       }

       @include respond(small) {
       //  font-size: 15px;
       }
    
   }

   .copywrite{
    padding-top: 10px;
    text-align: center;
    font-family: montserrat !important;
    font-size: 13px; 

   }
    // background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)),  url("../../assets/img/bg-10.png");
    // background-attachment: fixed;
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    // width: 100%;
    // height: max-content;
    // display: flex;
    // justify-content: center;
    // .head{
    //     height: 100%;
    //     display: flex;
    //     .contact{
    //         display: flex;
    //         flex-direction: column;
    //         align-items: center;
    //         color:white;
    //         p{
    //             width: 70%;
    //             text-align: center;
    //         }
    //         .button{
    //             color:#29a19c;
    //             padding:10px 20px;
    //             border-color: #29a19c;
    //             &:hover,&:focus,&:active{
    //                 outline:none !important;
    //                 box-shadow: none !important;
    //                 background-color: transparent !important;
    //                 color:#63c9c6 !important;
    //                 border-color: #63c9c6 !important;
    //                 transition: all 0.3 ease-in-out;
    //             }
    //         }
    //     }
    // }
    // .footer{
    //     position: relative;
    //     font-size: 12px;
    //     bottom: -50px;
    //     color: rgb(189, 189, 189);
    // }
}