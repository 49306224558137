@mixin respond($breakpoint) {
    @if $breakpoint == x-large {
      @media (max-width: 1160px) { @content };
    }
    @if $breakpoint == large {
      @media (max-width: 940px) { @content };
    }
    @if $breakpoint == medium {
        @media (max-width: 425px) { @content };
      }
    @if $breakpoint == small {
        @media (max-width: 320px) { @content };
      }
  }

.about{
   
    background-color: #e4e4e4;

    @include respond(large) {
        text-align: center;
      }
    
    .about-heading{
        padding-top: 100px;
        font-family: heavitas !important;
        font-size: 30px !important;
          @include respond(large) {
            padding-top: 50px;
          }  
    }
    .about-text{
        padding-top: 20px;
        padding-right: 60px;
        padding-bottom: 30px;
        font-family: montserrat !important;
        font-size: 15px !important;
        @include respond(large) {
            padding-right: 20px;
            padding-left: 20px;
            }
      
    }
    .about-image{
    background-image: url("../../assets/img/about4.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    }

    .about-space{
        @include respond(large) {
            padding-bottom: 45px;
          }
    }

    .btn{
        
        text-transform: uppercase;
        font-weight: 700;
        position: relative;
        overflow: hidden;
        display: inline-block;
        backface-visibility: hidden;
        // float: right;
        border: none;
        color: white;
        border-radius: 25px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px; 
        background: #C63A3A;
        padding: 10px 30px;
        font-size: 12px; 

        @include respond(large) {
            width: 125px;
            font-size: 11px; 
            padding: 10px 15px;
          }
    }
    // .text{
    //     max-width: 950px;
    // }
    // .skills-box{
    //     display: flex;
    //     max-width: 400px;
    //     width: 100%;
    //     justify-content: space-between;
    //     .lists{
    //         li{
    //             display: flex;
    //             align-items: center;
    //             &::before{
    //                 content:"▶";
    //                 position: relative;
    //                 margin-right: 10px;
    //                 left:0;
    //                 line-height: 11px;
    //                 font-size: 10px;
    //                 color: #63c9c6;
    //             }
    //         }
    //     }
    // }
}
